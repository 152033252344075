.navbar-brand-full {
    padding-left: 1em;
}

.mat-card-header-text {
    margin: 0 !important;
}

.modal {
    z-index: 9999 !important;
}

.swal2-actions {
    width: 100%;
}

.no-caret {
    padding: .25rem .75rem !important;

    &:after {
        content: initial;
    }
}

.small-ellipsis-button {
    height: 26px !important;
    line-height: 26px !important;
}

.widget-card {
    min-height: 64px;
}

.page-header {
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;

    .page-title {
        display: inline-block;
        margin-top: 0;
        font-weight: bold;
        color: #c8ced3;
    }

    .page-actions {
        float: right;
    }
}

.required:after {
    color: #e53935;
    content: "*";
    font-weight: bold;
}

.ngx-pagination {
    padding: 0 !important;
    margin: 0 !important;
}

.border-light {
    border-color: #e4e7ea !important;
}

.ng2-tag-input__text-input {
    width: 100px;
    font-family: "Arial", serif;
}

svg.fa-circle-xmark path {
    transition: 1s;
    fill: #e53935;
}

svg.fa-circle-check path {
    transition: 1s;
    fill: #43a047;
}

.blue {
    svg path {
        transition: 1s;
        fill: #1e88e5;
    }
}

.dropdown-item:not(.legend-item) {
    cursor: pointer;

    svg path {
        color: #c8ced3;
    }
}

.tab-container {
    display: inherit;
}

.strikethrough {
    text-decoration: line-through !important;
}

.ngx-file-drop__drop-zone {
    border: 2px dashed rgba(0, 0, 0, .12) !important;
    border-radius: 4px !important;
    margin-bottom: 1em !important;

    .ngx-file-drop__content {
        color: #212529 !important;
    }
}

.mat-dialog-actions {
    justify-content: space-between;
}

.button-filter-group {
    .mat-button-toggle-checked {
        .mat-focus-indicator {
            font-weight: bold !important;
        }
    }

    .mat-button-toggle-label-content {
        padding: 0 30px !important;
        line-height: 26px !important;
    }
}
