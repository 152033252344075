.breadcrumb {
    margin-bottom: 0;
    padding: 0 1em;
    list-style: none;
    border-bottom: none;
    background: #ffffff !important;

    .breadcrumb-item {
        display: inline-block;
        padding: 1em 0;
        text-decoration: none;
        font-weight: 500;

        a {
            color: rgba(0, 0, 0, .8) !important;
        }

        &.active a {
            color: rgba(0, 0, 0, .54) !important;
            font-weight: 400;
        }
    }

    .breadcrumb-item + .breadcrumb-item::before {
        content: "";
    }

    .breadcrumb-separator {
        display: inline-grid;
        padding: 0 1em;
        align-content: center;
    }
}
