@use 'variables';

@use '@sweetalert2/themes/material-ui/material-ui.scss';
@use 'quill/dist/quill.snow.css';
@use 'ag-grid-community/dist/styles/ag-grid.css';
@use 'ag-grid-community/dist/styles/ag-theme-material.css';

@use 'breadcrumbs';
@use 'forms';
@use 'tables';
@use 'activities';
@use 'custom';

.app {
    background: #fafafa;
}

.mat-drawer-content {
    overflow-x: hidden !important;
}

.flex-spacer {
    flex: 1 1 auto !important;
}

.ag-theme-alpine {
    --ag-borders: none !important;
}

.ag-overlay-no-rows-wrapper {
    padding-top: 5em;
}

.ag-ltr .ag-body,
.ag-ltr .ag-floating-top,
.ag-ltr .ag-floating-bottom,
.ag-ltr .ag-header,
.ag-ltr .ag-body-viewport,
.ag-ltr .ag-body-horizontal-scroll {
    min-height: 100px;
}

.ag-center-cols-container {
    min-width: 100%;
}

.ag-root-wrapper-body.ag-layout-normal {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    overflow: visible;
}

.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus {
    z-index: 999;
}

.ag-row.ag-row-level-0.ag-row-position-absolute .ag-cell-focus {
    overflow: visible;
}

.page-header {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 99;
    padding: 1em;
    border-bottom: 1px solid #c8ced3;
}

.panel {
    border-radius: 4px;
    margin: 24px 0;

    &.bordered {
        border: 1px solid rgba(0, 0, 0, .2);
    }

    .panel-header {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 20px 16px;
        color: #0000008a;
        background: #0000000a;

        .panel-title-spacer {
            flex: 1 1 auto;
        }
    }

    .panel-body {
        padding: 8px;
    }
}

.mat-form-field {
    width: 100%;
}

.small-icon-button {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;

    .mat-icon {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px !important;
    }

    .material-icons {
        font-size: 16px !important;
    }
}

.page-card {
    height: 86vh !important;
}

button {
    &:disabled {
        cursor: not-allowed;
    }
}

quill-editor {
    display: flex !important;
    flex-direction: column;
    border-top: 1px solid #cccccc;

    .ql-editor.ql-blank::before {
        font-size: 14px;
        font-style: normal;
    }

    .ql-container .ql-editor {
        min-height: 120px;
        height: 120px;
    }

    .ql-snow .ql-picker.ql-expanded .ql-picker-options {
        display: block;
        margin-top: -1px;
        top: 100%;
        z-index: 1;
    }

    .ql-toolbar.ql-snow {
        order: 2;
        border-top: none !important;
    }
}

.status-chip {
    padding: 4px 9px !important;
    min-height: 22px !important;
}

.small-chip {
    font-size: 13px !important;
    padding: 7px 9px !important;
    min-height: 25px !important;
}

.mat-input-element {
    color: initial !important;
}
