
html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
.error-message {
    color: red;
  }

  .calmag-logo{
    margin-left: 134px
  }