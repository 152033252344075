fieldset {
    margin-bottom: 1.5em;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    color: #0000008a;

    legend {
        background-color: #fafafa;
        padding: 1px 15px;
        border: 1px solid rgba(0, 0, 0, .2) !important;
        border-radius: 4px;
        font-size: .8em;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .5px;
    }
}

mat-radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    align-items: flex-start;

    mat-label {
        color: rgba(0, 0, 0, .54);
    }

    mat-radio-button {
        margin: 5px;

        .mat-radio-label-content {
            color: rgba(0, 0, 0, .54);
        }
    }
}
