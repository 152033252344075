.activity {
    display: flex;
    clear: right;
    position: relative;
    margin-bottom: 2em;

    .mat-expansion-panel {
        width: 100%;
    }

    .mat-expansion-panel-header-description {
        flex-grow: 0;
    }

    .mat-action-row {
        justify-content: space-between !important;
        padding: 16px 24px !important;
    }

    &:not(.first-timeline-item):before {
        background: #e0e0e0;
        content: "";
        height: 120%;
        left: 1.8em;
        position: absolute;
        top: 20px;
        width: 2px;
    }

    .card {
        max-height: 300px;
    }

    .timeline-icon {
        margin-top: 20px;
        width: 40px;
        height: 40px;
        background: #e0e0e0;
        border-radius: 100%;
        text-align: center;
        z-index: 1000
    }

    .activity-content {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        padding: 1.5em 1em 0;
        border: 1px solid #e9e9e9;
        border-radius: 4px;

        .activity-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .activity-content-container {
            border: 2px solid transparent;
        }

        .activity-footer {
            margin-top: 5em;
        }
    }
}

.activity-description {
    margin: 0;
    padding: 1.5em;
    border-radius: 4px;
    background: #fafafa;

    & * {
        margin-bottom: 0 !important;
    }
}

.list {
    padding: 0;
    list-style: none;

    .list-header {
        color: rgba(0, 0, 0, .54);
    }

    .list-data {
        color: rgba(0, 0, 0, .87);
    }
}

.file-upload-preview {
    margin: 0.5rem;
    justify-self: center;
    align-self: center;
    border-radius: 20px;
    background: #999999;
    background: linear-gradient(to bottom, #eeeeee, #dddddd);
    color: #23282c;
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 120px;
    width: 120px;

    .file-upload-details {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        max-width: 100%;
        padding: 2em .5em;
        box-sizing: border-box;
        z-index: 20;
        font-size: 13px;
        text-align: center;
        color: rgba(0, 0, 0, .9);
        line-height: 150%;

        .file-upload-size {
            margin-bottom: 1em;
            font-size: 16px;

            span {
                background-color: rgba(255, 255, 255, .4);
                padding: 0 0.4em;
                border-radius: 3px;
            }
        }

        .file-upload-name {
            white-space: nowrap;
            box-sizing: border-box;

            &:hover {
                border: 1px solid rgba(200, 200, 200, .8);
                background-color: rgba(255, 255, 255, .8);

                span {
                    border: 1px solid rgba(200, 200, 200, .8);
                    background-color: rgba(255, 255, 255, .8);
                }
            }

            &:not(:hover) {
                overflow: hidden;
                text-overflow: ellipsis;

                span {
                    border: 1px solid transparent;
                }
            }

            span {
                background-color: rgba(255, 255, 255, .4);
                padding: .2em .4em;
                border-radius: 3px;
            }
        }
    }
}
